<template>
  <div class="noScore">
    <!-- 搜索输入框 -->
    <div class="search" v-if="count > 0">
      <el-input
        placeholder="请输入参赛作品编号"
        v-model="searchInput"
        clearable
        @change="onChnage()"
        class="searchInput"
      >
      </el-input
      ><el-button type="primary" @click="searchBM()" class="searchStyle"
        >搜索</el-button
      >
    </div>
    <!-- 作品列表 -->
    <ul class="boxs" v-if="notOpusLists.length > 0">
      <li v-for="item in notOpusLists" :key="item.bm_id">
        <div class="csbh">参赛编号：{{ item.bm_id }}</div>
        <div class="auto" @click="toDetailsChange(item.bm_id)">
          <video
            v-if="item.path"
            width="100%"
            preload="meta"
            height="100%"
            :controls="false"
            style="object-fit: fill"
            ref="video"
          >
            <source :src="aliyunURL + item.path" type="video/mp4" />
            <source src="movie.ogg" type="video/ogg" />
            您的浏览器不支持Video标签。
          </video>
        </div>
        <div class="text">
          <p>
            <i
              v-if="item.difference == 1"
              style="color: red; padding-right: 5px"
              class="el-icon-warning"
            ></i
            >
            <!-- {{ item.intro }} -->
          </p>
          <span>{{ item.addDate }}</span>
        </div>
      </li>
    </ul>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="pageSize.page"
      layout="prev, pager, next"
      :total="count"
      :page-size="6"
      :hide-on-single-page="false"
    >
    </el-pagination>
  </div>
</template>
<script>
import { judgesGetOpusLists } from "../JudegeAPI/user";
import { getTokenJ } from "../../assets/js/Storage-J";

export default {
  data() {
    return {
      searchInput: "",
      count: 0,
      notOpusLists: [],
      aliyunURL: "https://vod.gyycbj.com/",
      pageSize: {
        page: 1,
        limit: 6,
      },
    };
  },
  created() {
    this.getOpusListHandle();
  },
  mounted() {},
  methods: {
    // 搜索编号
    searchBM() {
      this.pageSize.page = 1;
      this.getOpusListHandle();
    },
    // 分页切换
    handleCurrentChange(val) {
      this.pageSize.page = val;
      this.getOpusListHandle();
    },
    // 输入框change
    onChnage() {
      if (this.searchInput == "") {
        this.pageSize.page = 1;
        this.getOpusListHandle();
      }
    },
    // 获取评委作品列表
    async getOpusListHandle() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
      const { data: res } = await judgesGetOpusLists({
        ...this.pageSize,
        j_id: getTokenJ().j_id,
        state: 0,
        sousuo: this.searchInput,
        phase: localStorage.getItem("phase"),
      });
      loading.close();
      if (res.code !== "0000") {
        return this.$message(res.msg);
      }
      this.count = res.data.count;
      this.notOpusLists = res.data.list;
    },
    // 跳转至详情
    toDetailsChange(id) {
      this.$router.push({
        name: "noScoreDetails",
        params: {
          id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.noScore {
  .search {
    display: flex;
    margin: 0 30px;
    padding-top: 15px;
    .searchInput {
      width: 200px;
      ::v-deep .el-input__inner:focus {
        border-color: #5b2d9b;
      }
    }
    .searchStyle {
      color: #ffffff;
      background-color: #5b2d9b;
      border-color: rgba(0, 0, 0, 0);
    }
  }
  .boxs {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    padding-top: 0;
    li {
      width: 31%;
      margin: 15px;
      height: min-content;
      border: 1px solid #d5d5d5;
      border-radius: 5px;
      box-sizing: border-box;
      padding: 20px;
      .csbh {
        text-align: center;
        color: #666;
        padding-bottom: 5px;
      }
      .auto {
        height: 220px;
        width: 100%;
        background-color: #333089;
        cursor: pointer;
      }
      .text {
        p {
          cursor: pointer;
          color: #333333;
          font-size: 20px;
          padding: 15px 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        p:hover {
          color: #333089;
        }
        span {
          display: block;
          text-align: right;
          color: #999;
        }
      }
    }
  }
  ::v-deep .el-pagination {
    text-align: center;
    .el-pager li.active {
      color: #ffffff;
    }
  }
}
</style>
